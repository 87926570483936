<template>
  <div>
    <section class="bg-white rounded-xl p-4 mb-4 shadow-md md:bg-gray-10 md:shadow-none flex flex-col md:flex-row" v-if="initDone">
      <img class=" mr-8 rounded-lg hidden md:block w-72 h-72 object-fill" :src="item.product.image" :onerror="imgNotFound" @click="handleClickImage"/>
      <div class="flex-1 flex flex-col">
        <!--描述-->
        <article class="flex flex-row mb-2 items-center justify-between">
          <img class="cart-item-img mr-2 md:hidden" :src="item.product.image" :onerror="imgNotFound" @click="handleClickImage"/>
          <div>
            <p class="text-sm opacity-60 text-right mb-1 md:text-left">{{ item.time }}加入</p>
            <p class="text-brown font-medium md:text-left md:text-2xl md:mb-0">{{ item.product.name }}</p>
          </div>
        </article>
        <!--數量-->
        <article>
          <ul class="mb-0">
            <li class="p-3 bg-gray-50 rounded-md mb-1 md:flex md:flex-col md:justify-between" v-for="(pack, packIdx) in form.packs" :key="packIdx">
              <section class="flex flex-col">
                <!--幾入組-->
                <article class="flex flex-row justify-between items-center mb-2 md:w-2/5">
                  <span class="text-brown">{{ pack.name }} <span class="text-red-400" v-if="pack.status == 0">已刪除!</span></span>
                </article>
                <!--價格/入組-->
                <article class="flex flex-col mb-1">
                  <div class="flex flex-row justify-between items-center">
                    <div class="mr-4">
                      <span class="font-bold text-pink-salmon mr-1">${{ pack.price.grouped | currency }}</span><span class="text-gray-400"><small> /{{pack.unit == '' ? '入' : pack.unit}}</small></span>
                    </div>
                    <div class="flex justify-end">
                      <div v-if="!loading"><span class="font-bold text-pink-salmon text-right">${{ pack.price.total | currency }}</span></div>
                      <a-spin v-if="loading">
                        <a-icon slot="indicator" type="loading" style="font-size: 24px" spin />
                      </a-spin>
                    </div>
                  </div>
                </article>
              </section>
              <!--如果沒有規格要隱藏-->
              <section class="flex flex-row justify-between md:flex-1" v-if="pack.status == 1">
                <div class="w-1/2 pr-1">
                  <!--Mobile Select-->
                  <div v-if="form.specs.length > 0" class="ant-input c-btn-select md:hidden text-shorter hidden" @click="onSpecModal(packIdx)">
                    <span v-if="pack.spec !== 0"> {{ getSpecName(pack.spec) }}</span>
                    <span v-if="pack.spec == 0" class="text-gray-400">請選擇規格</span>
                  </div>
                  <!--PC Select-->
                  <a-select v-if="form.specs.length > 0" placeholder="請選擇規格" class="hidden md:block md:hidden" v-model="pack.spec">
                    <a-select-option v-for="(spec, specIdx) in form.specs" :value="spec.id" :key="specIdx">
                      {{ spec.name }}
                    </a-select-option>
                  </a-select>
                </div>
                <div class="w-1/2 pl-1">
                  <!-- TODO max數量 msg.packs.*.stock-->
                  <input-minus-plus :inputIdx="packIdx" :value="form.packs[packIdx].quantity" :min="0" @emitCounter="counterUpdate"></input-minus-plus>
                </div>
              </section>
              <section v-if="form.packs[packIdx].specs.length > 0">
                <!-- 多規格 -->
                <cart-order-item :specs.sync="form.packs[packIdx].specs" :quantity.sync="form.packs[packIdx].quantity" :size.sync="form.packs[packIdx].size" @renewCart="debounceRenewCart"/>
              </section>
            </li>
          </ul>
        </article>
        <!--總計-->
        <article class="p-3 border-solid border-b border-gray-200 md:border-none flex justify-end">
          <div class="flex flex-row justify-between md:w-1/2 w-full">
            <div class="flex flex-row justify-between w-full order-1 md:order-2 md:w-2/5">
              <span class="text-brown font-thin">均價</span>
              <a-spin v-if="loading">
                <a-icon slot="indicator" type="loading" style="font-size: 24px" spin />
              </a-spin>
              <div v-if="!loading"><span class="text-pink-salmon mr-1">${{ Math.floor(form.price.average) | currency }}</span><span class="text-gray-400"><small> /入</small></span></div>
            </div>
            <div class="w-10 text-center text-gray-200 md:hidden order-2">|</div>
            <div class="flex flex-row justify-between w-full order-3 md:order-1 md:w-2/5">
              <span class="text-brown font-bold">總金額</span>
              <a-spin v-if="loading">
                <a-icon slot="indicator" type="loading" style="font-size: 24px" spin />
              </a-spin>
              <span v-if="!loading" class=" font-bold text-pink-salmon">${{ form.price.total | currency }}</span>
            </div>
          </div>
        </article>
        <!--下方按鈕-->
        <section class="w-full p-3 pb-0 flex justify-end">
          <div class="flex flex-row justify-between md:w-1/2 w-full">
            <button class="btn-forms btn-light w-2/5" @click="handleDeleteOrder(form.id)">刪除</button>
            <button class="btn-forms w-full" :disabled="!unlockButton" @click="handlePayment(form.id)">付款</button>
          </div>
        </section>
      </div>
    </section>

    <!--Modal選擇規格-->
    <a-modal transitionName="fade" class="ant-modal-bottom" title="選擇規格" :closable="false" v-model="modals.spec.isShow">
      <template v-if="initDone">
        <article class="border-b mb-1 pb-2">
          <a-radio-group v-model="modals.spec.currentSpecId" :default-value="modals.spec.currentSpecId" class="pills">
            <a-radio-button v-for="(spec, specIdx) in item.specs" :key="specIdx" :value="spec.id" @click="onSpecRadio($event, spec.name, specIdx)">
              {{ spec.name }}
            </a-radio-button>
          </a-radio-group>
        </article>
      </template>

      <template slot="footer">
        <!--按鈕-->
        <div class="btn-bottom-groups">
          <button class="btn-forms btn-light" @click="onModalSpecCancel">取消</button>
          <button class="btn-forms" @click="onModalSpecOK()">確定</button>
        </div>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import InputMinusPlus from '@/components/InputMinusPlus.vue'
import CartOrderItem from '@/views/Cart/CartOrderItem.vue'
import { removeFromCartEvent } from '@/utils/ga.js'
import _ from 'lodash'

export default {
  name: 'CartOrder',
  components: {
    InputMinusPlus,
    CartOrderItem,
  },
  props: ['item', 'itemIdx'],
  data() {
    return {
      loading: false,
      CART_ID: '',
      isDebug: true,
      imgNotFound: 'this.src="' + require('../../assets/img/status/not_found_img_72x72.jpg') + '"',
      form: {},
      modals: {
        spec: {
          isShow: false,
          currentPackIdx: '',
          currentSpecId: '',
          currentSpecName: '',
          currentSpecIndex: ''
        }
      },
      initDone: false,
      debounceRenewCart: '',
      shouldSave: false
    }
  },
  methods: {
    ...mapActions(['deleteCartData', 'renewCart', 'getCartDataWithoutLoading']),
    initData() {
      let obj = JSON.parse(JSON.stringify(this.item))
      if (obj.specs.length > 0) {
        obj.specs.push({ id: 0, name: '請選擇規格' })
      }
      this.form = obj
      this.initDone = true
    },
    counterUpdate(v, idx) {
      // input數字加減
      this.form.packs[idx].quantity = v
      this.handleRenewCart()
    },
    getSpecName(id) {
      //從規格id找規格名稱
      let obj = JSON.parse(JSON.stringify(this.form.specs))
      let name = '請選擇規格'
      obj.forEach(spec => {
        if (spec.id == id) {
          name = spec.name
        }
      })
      return name
    },
    setModalHide(item) {
      this.modals[item].isShow = false // 關跳彈
    },
    setModalShow(item) {
      this.modals[item].isShow = true // 開跳彈
    },
    onSpecModal(packIdx) {
      // 寫入這是第幾個pack,讓更新時有參數使用
      this.modals.spec.currentPackIdx = packIdx
      this.modals.spec.currentSpecId = this.item.packs[packIdx].spec
      this.setModalShow('spec')
    },
    async onSpecRadio(e, name, index) {
      // 寫入 名稱,index,值 到modals.spec
      this.modals.spec.currentSpecId = e.target.value
      this.modals.spec.currentSpecName = name
      this.modals.spec.currentSpecIndex = index
    },
    onModalSpecCancel() {
      // 清除舊值
      this.modals.spec.currentSpecId = 0
      this.modals.spec.currentSpecName = ''
      this.modals.spec.currentSpecIndex = 0
      this.setModalHide('spec') // 關跳彈
    },
    async onModalSpecOK() {
      //更新calc
      // this.calc.packs[this.modals.spec.currentPackIdx].spec = this.modals.spec.currentSpecId // id值
      // this.calc.packs[this.modals.spec.currentPackIdx].specName = this.modals.spec.currentSpecName // name

      //更新form
      this.form.packs[this.modals.spec.currentPackIdx].spec = this.modals.spec.currentSpecId

      await this.setModalHide('spec') // 關跳彈
      this.handleRenewCart()
    },
    handleClickImage() {
      this.$router.push({ name: 'productDetail', params: { id: this.item.product.id } })
    },
    async handleRenewCart() {
      // * api 更新購物車
      const formData = new FormData()
      const id = String(this.CART_ID)
      const data = JSON.stringify(this.form)
      await formData.append('id', Number(id))
      await formData.append('data', data)
      this.loading = true
      await this.renewCart({ id, formData })

      // * api 取得最新購物車資料並更新到form
      await this.getCartDataWithoutLoading()
      this.form = this.item
      this.loading = false
    },
    handleDeleteOrder(id) {
      //* api 刪除
      this.deleteCartData(id)

      const item = this.handleRemoveFromCartEventData()
      this.$gtm.trackEvent(removeFromCartEvent(item))
    },

    /**
     * 代資料去Payment選單
     *
     * @param {object} form - 商品詳細,訂購數量.
     * @param {number} itemIdx - 第幾個items
     */
    handlePayment() {
      this.$router.push({ name: 'payment', params: { form: this.form, total: this.form.price.total, itemIdx: this.itemIdx } })
    },
    handleRemoveFromCartEventData() {
      return [{
        name: this.item.product.name,
        id: this.item.product.id,
        price: this.item.packs[0].price.grouped,
        quantity: 1
      }]
    }
  },
  computed: {
    isRequirePassed() {
      //商品本身沒有規格
      if (this.form.specs.length == 0) {
        if (this.form.price.total > 0) return true
        else return false
      }

      //TODO 多規格要改成任選的前端檢核
      //商品有規格 
      let result = true
      // let array = []
      // this.form.packs.map(pack => {
      //   //將多種可能性放入陣列判斷
      //   if (pack.spec == 0 && pack.quantity > 0) array.push(false) // 沒規格 有數量
      //   if (pack.spec == 0 && pack.quantity == 0) array.push(true) // 沒規格 沒數量 (total 0會false
      //   if (pack.spec != 0 && pack.quantity == 0) array.push(true) // 有規格 沒數量
      //   if (pack.spec != 0 && pack.quantity > 0) array.push(true) // 有規格 有數量
      //   if (this.form.price.total == 0) array.push(false) // 沒價格
      // })

      // //只要有找到不合格的就不能送單
      // // console.log('array', array)
      // result = !array.some(x => x == false)

      // //傳送結果
      // // console.log('result', result)
      return result
    },
    unlockButton() {
      //如果正在更新中鎖定按鈕
      if (this.isRequirePassed === true && this.loading === false) return true
      else return false
    }
  },
  watch: {
    'form.packs': {
      handler: function() {
        this.form.packs.forEach(pack => {
          let total = pack.quantity * pack.size
          let selectedArr = pack.specs.map(spec => spec.quantity == undefined ? 0 : spec.quantity)
          let selectedTotal = selectedArr.length > 0 ? selectedArr.reduce((a, b) => a + b) : 0
          if(selectedTotal > total) {
            pack.specs.forEach(spec => {
              spec.quantity = 0
            })
          }
        })
      },
      deep: true,
      // immediate: true
    }
  },
  mounted() {
    this.initData()
    this.CART_ID = this.item.id
    this.debounceRenewCart = _.debounce(this.handleRenewCart, 500)
  }
}
</script>

<style lang="postcss" scope>
.c-btn-select {
  display: flex;
  border-radius: 0.5rem;
  border: 1px solid #d9d9d9;
  height: 32px;
}
.c-btn-select::after {
  content: '';
  background-image: url('~@/assets/img/ui/ui_triangle_down.svg');
  background-size: 100% 100%;
  height: 8px;
  width: 8px;
  top: 10px;
  right: 10px;
  position: absolute;
  display: inline-block;
}
.ant-select-selection {
  @apply rounded-lg;
  height: 32px;
}
.ant-select-selection__rendered {
  line-height: 32px;
}
.ant-radio-button-wrapper {
  height: auto;
}
.text-shorter span:first-child {
  @apply truncate;
  width: calc(100% - 10px);
}

/* slide動畫 */
/* .slide-enter-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}

.slide-leave-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
}

.slide-enter-to,
.slide-leave {
  max-height: 100px;
  overflow: hidden;
}

.slide-enter,
.slide-leave-to {
  overflow: hidden;
  max-height: 0;
} */
</style>
