<template>
  <article class="h-inherit pt-12 md:h-auto md:pt-0 md:max-w-1200 md:mx-auto">
    <div class="bg-gray-100 md:bg-white min-h-screen h-min-full rounded-t-3xl">
      <div class="container-wrapper md:px-0">
        <h1 class="text-xl md:text-3xl text-brown my-2 md:mb-8">購物車</h1>

        <template v-if="items">
          <template v-for="(item,itemIdx) in items" >
            <cart-order :item="item" :key="item.id" :itemIdx='itemIdx'></cart-order>
          </template>
        </template>

      </div>
    </div>
  </article>
</template>
<script>
import { mapActions,mapGetters } from 'vuex';
import CartOrder from '@/views/Cart/CartOrder.vue'
import { checkout } from '@/utils/ga.js'

export default {
  name: 'Cart',
  components: {
    CartOrder
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters(['items']),
  },
  methods: {
    ...mapActions(['getCartData']),
    handleGaCheckoutData() {
      const items = []
      this.items.forEach(el => {
        const packs = el.packs.map(e => {
          return {
            name: `${el.product.name} - ${e.name}`,
            id: e.id,
            price: e.price.total,
            quantity: e.quantity
          }
        })
        items.push(...packs)
      })
      return items
    }
  },
  mounted(){
    this.getCartData()
    
    const items = this.handleGaCheckoutData()
    this.$gtm.trackEvent(checkout({ step: 1 }, items))
  }
}
</script>
