<template>
  <div class="w-full mt-3">
    <div class="flex justify-between" :class="{ 'mb-1': displaying }">
      <span>{{selectedTotal}}/{{total}} 已選</span>
      <button class="focus:outline-none mb-2" @click="displayToggle">
        <span class="opacity-60 mr-2">{{ statusText }}</span>
        <img class="btn-ico-triangle transform opacity-100" :class="{ 'rotate-180': displaying }" src="@/assets/img/ui/ui_triangle_down.svg" />
      </button>
    </div>
    <transition name="slide">
      <div v-if="displaying" class="block md:flex flex-row flex-wrap bg-gray-50">
        <div v-for="(spec, specIdx) in specs" :key="specIdx">
          <div v-if="spec.name != '請選擇規格'" class="px-3 py-2 mx-2 my-1 bg-white rounded border-solid border-2 border-gray-200 flex items-center"> 
            <span class="whitespace-nowrap mr-2">{{ spec.name }}</span>
            <input-minus-plus class="md:w-20" :isEnable="isValidTotal" :inputIdx="specIdx" :max="specs[specIdx].stock" :value="specs[specIdx].quantity" @emitCounter="counterUpdate"></input-minus-plus>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import InputMinusPlus from '@/components/cart/InputMinusPlus.vue'

export default {
  components: {
    InputMinusPlus
  },
  props: { 
    specs: {},
    quantity: {},
    size: {}
  },
  data() {
    return {
      displaying: true
    }
  },
  computed: {
    statusText() {
      if (this.displaying) {
        return ''
      }
      return ''
    },
    total() {
      return this.quantity * this.size
    },
    selectedTotal() {
      let selectedArr = this.specs.map(spec => { return spec.quantity == undefined ? 0 : spec.quantity })

      return selectedArr.length == 0 ? 0 : selectedArr.reduce((a, b) => a + b)
    },
    isValidTotal() {
      //let selectedArr = this.specs.map(spec => { return spec.quantity == undefined ? 0 : spec.quantity })
      //let selectedTotal = selectedArr.length == 0 ? 0 : selectedArr.reduce((a, b) => a + b)

      if(this.selectedTotal >= this.total) {
        return false
      }

      return true
    }
  },
  methods: {
    displayToggle() {
      this.displaying = !this.displaying
    },
    counterUpdate(v, idx) {
      this.specs[idx].quantity = v
      // if (this.isValidTotal) {
      this.$emit('renewCart');
      // }
    }
  }
}
</script>

<style lang="scss" scoped>
.spec-input {
  max-width: 80px;
}

.btn-ico-triangle{
  width:12px;
  height:12px;
  padding:0;
  margin: 0 1px 2px;
}
</style>