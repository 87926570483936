<template>
  <div class="minusplusnumber" :class="!isEnable ? 'disabled-div' : ''">
    <div class="mpbtn minus mpbtn-left" v-on:click="mpminus()">
      -
    </div>

    <input :disabled="!isEnable" class="showNumber" :class="!isEnable ? 'disabled-input' : ''" type="number" v-model="newValue" @change="checkValue">
    <!-- <div class="showNumber">{{newValue}} <span v-if="max" class="opacity-50">/{{max}}</span> </div> -->

    <div class="mpbtn plus mpbtn-right" :class="!isEnable ? 'disabled-btn' : ''" v-on:click="mpplus()">
      +
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      default: 0,
      type: Number
    },
    min: {
      default: 0,
      type: Number
    },
    max: {
      default: undefined,
      type: Number
    },
    inputIdx: {
      default: undefined,
      type: Number
    },
    isEnable: {
      default: true,
      type: Boolean
    }
  },
  data() {
    return {
      newValue: 0
    };
  },
  methods: {
    getNotificationClass(notification) {
      return `alert alert-${notification.type}`;
    },
    checkValue: function(){
      if(this.newValue < 0){
        this.newValue = 0
      }
      this.$emit('emitCounter', this.newValue,this.inputIdx);
    },
    mpplus: function() {
      if (this.max === undefined || this.newValue < this.max) {
        this.newValue = this.newValue + 1;
        this.$emit('emitCounter', this.newValue,this.inputIdx);
      }
    },
    mpminus: function() {
      if (this.newValue > this.min) {
        this.newValue = this.newValue - 1;
        this.$emit('emitCounter', this.newValue,this.inputIdx);
      }
    },
  },
  watch: {
    value: {
      handler: function(newVal) {
        this.newValue = newVal;
      }
    },
    // isEnable: {
    //   handler: function() {
    //     console.log('hahahahah')
    //     console.log(this.isEnable)
    //   },
    //   immediate: true
    // }
  },
  created: function() {
    this.newValue = this.value;
  }
};
</script>
<style scoped>
.minusplusnumber {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  background: #fff;
  /* border: 1px solid #d9d9d9; */
  user-select: none;
  border-radius: 0.5rem;
  width: 100%;
  height: 32px;
}
.minusplusnumber .showNumber {
  width: 100%;
  text-align: center;
  font-size: 15px;
  background: #fff;
  /* border: 1px solid #d9d9d9; */
  border-top: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
  line-height: 28px;
}
.minusplusnumber .mpbtn {
  /* background: #fff; */
  align-items: center;
  align-self: center;
  /* border-radius: 0.5rem; */
  cursor: pointer;
  display: flex;
  font-size: 1rem;
  font-weight: bold;
  height: inherit;
  justify-content: center;
  text-align: center;
  width: 40px;
}

.minusplusnumber .mpbtn-left {
  border-radius: 0.5rem 0rem 0rem 0.5rem;
  border: 1px solid #d9d9d9;
  background-color: #fff;
}

.minusplusnumber .mpbtn-right {
  border-radius: 0rem 0.5rem 0.5rem 0rem;
  border: 1px solid #d9d9d9;
}

.minusplusnumber .mpbtn:focus {
  background-color: #ddd;
}

/* input number =========================*/
input:focus-visible{
  outline: none
}
/* Hide input number type up down arrow */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.disabled-btn {
  pointer-events: none;
  /* background-color: rgb(211, 211, 211); */
}

.disabled-input {
  background-color: rgb(224, 224, 224) !important;
}

.disabled-div {
  background-color: rgb(211, 211, 211);
}
</style>
